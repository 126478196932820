<script>
import { clientMethods, masterMethods, employeeMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Footer from '@/components/Footer/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

export default {
    page: {
        title: '直クラ・代理店一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        KeyWordTag,
        Footer,
        TableListComponent
    },
    data() {
        return {
            listData: [],
            form: {
                name: '',
                pic: ''
            },
            has: {
                sale_staff: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: '会社名　担当者　会社名(カナ)　電話番号　郵便番号　都道府県　住所　URL',
                isShowLable: true
            },
            configTable: {
                headers: [
                    {
                        label: '会社名'
                    },
                    {
                        label: '会社名(カナ)'
                    },
                    {
                        label: '実施中キャンペーン数'
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            },
            listPLSale: [],
            checkShowFilter: false,
            isLoading: false,
            arrRoleClient: ['client.view', 'client.edit']
        };
    },
    watch: {},
    mounted() {
        this.getList();
        this.getListMasterPLSale();
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,
        ...employeeMethods,
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
        },
        getListMasterPLSale() {
            this.listEmployee('filters[role]=2&limit=999').then((data) => {
                this.listPLSale = data.data;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listClient(query).then((data) => {
                this.listData = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            });
        },

        search() {
            let data = {
                form: this.form,
                q: this.q,
                has: this.has,
                filter: this.configKeyword.arrayTag
            };
            localStorage.setItem('client', JSON.stringify(data));
            this.getList();
        },
        clear() {
            this.form = {
                name: '',
                pic: ''
            };
            this.q = '';
            this.has = {
                sale_staff: ''
            };
            this.configKeyword.arrayTag = [];
            let data = {
                form: this.form,
                filter: this.configKeyword.arrayTag,
                has: this.has
            };
            localStorage.setItem('client', JSON.stringify(data));
            this.getList();
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">直クラ・代理店検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :keyword="form.keywords" :config="configKeyword" class="flex-fill" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">会社名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">PL営業担当</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex">
                                                    <select v-model="has.sale_staff" class="form-control" id="">
                                                        <option :value="item.id" v-for="item in listPLSale" :key="item.id">
                                                            {{ item.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">エンドクライアント担当名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.pic" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-danger btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            直クラ・代理店検索結果 <span>件数（{{ configPage.total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row }">
                                <tr @dblclick="goToEdit('/client/form/view', row)">
                                    <td>
                                        <div class="row-data-small">
                                            <a href="javascript:void(0)" @click="goToEdit('/client/form/view', row)">{{ row.name }}</a>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.kana_name ? row.kana_name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    $router.push({
                                                        path: '/campaign/list',
                                                        query: { client_id: row.id }
                                                    })
                                                "
                                                >({{ row.campaigns_count }})</a
                                            >
                                        </div>
                                    </td>

                                    <td>
                                        <div class="row-data-small">
                                            <a href="javascript:void(0)" class="text-info border-right-1" @click="goToEdit('/client/form/view', row)"
                                                >詳細</a
                                            >
                                            <a
                                                v-if="checkPermission(arrRoleClient[1])"
                                                href="javascript:void(0)"
                                                class="text-success"
                                                @click="goToEdit('/client/form', row)"
                                                >編集</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped></style>
